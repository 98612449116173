import type { AxiosRequestConfig } from 'axios';

import type {
  BuyerBusinessCreatePGCUser,
  BuyerBusinessCreatePayload,
  BuyerBusinessEntity,
  BuyerBusinessUpdateDataPayload,
  BuyerBusinessUpdatePGCToken,
  BuyerBusinessUpdatePayload,
  LinkUserToBusinessPayload,
} from '../../types/buyers';

import type {
  AxiosResponseWithError,
  MerchantRepresentativeEntity,
  MsApiResponse,
  PagedDataSource,
  SearchParams,
} from '../../types/api';
import type { CrudResource, GetResource } from '../base';
import BaseResource from '../base';
import { MsAdminApiEndPoints } from './endPoints';

export class BuyersResource
  extends BaseResource
  implements GetResource<BuyerBusinessEntity>, CrudResource<BuyerBusinessEntity>
{
  /**
   * Retrieves a buyer by matching field.
   */
  getBy(): Promise<AxiosResponseWithError<BuyerBusinessEntity>> {
    throw new Error('Method not implemented.');
  }

  /**
   * Retrieves a buyer by buyer id.
   */
  get(id: string, options?: AxiosRequestConfig): Promise<AxiosResponseWithError<BuyerBusinessEntity>> {
    return this.client.get<BuyerBusinessEntity, MsApiResponse<BuyerBusinessEntity>>(
      MsAdminApiEndPoints.buyers.get(id),
      options
    );
  }

  /**
   * Retrieve all buyers.
   */
  list(
    searchParams?: Partial<SearchParams>,
    options?: AxiosRequestConfig
  ): Promise<MsApiResponse<PagedDataSource<BuyerBusinessEntity>>> {
    return this.client.get<BuyerBusinessEntity[], MsApiResponse<PagedDataSource<BuyerBusinessEntity>>>(
      MsAdminApiEndPoints.buyers.list(),
      {
        params: searchParams,
        ...options,
      }
    );
  }

  /**
   * Create a buyer.
   */
  create(
    payload: BuyerBusinessCreatePayload,
    options?: AxiosRequestConfig<BuyerBusinessCreatePayload>
  ): Promise<AxiosResponseWithError<BuyerBusinessEntity>> {
    return this.client.post<BuyerBusinessEntity, MsApiResponse<BuyerBusinessEntity>, BuyerBusinessCreatePayload>(
      MsAdminApiEndPoints.buyers.list(),
      payload,
      options
    );
  }

  /**
   * Retrieves a buyer by slug.
   */
  getBySlug(slug: string, options?: AxiosRequestConfig): Promise<AxiosResponseWithError<BuyerBusinessEntity>> {
    return this.client.get<BuyerBusinessEntity, MsApiResponse<BuyerBusinessEntity>>(
      MsAdminApiEndPoints.buyers.getBySlug(slug),
      options
    );
  }

  /**
   * Retrieves a buyer's account representative.
   */
  getAccountManager(options?: AxiosRequestConfig): Promise<AxiosResponseWithError<MerchantRepresentativeEntity>> {
    return this.client.get<MerchantRepresentativeEntity, MsApiResponse<MerchantRepresentativeEntity>>(
      MsAdminApiEndPoints.buyers.getAccountManager(),
      options
    );
  }

  /**
   * Update a buyer's details.
   */
  update(
    id: string,
    payload: BuyerBusinessUpdateDataPayload,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponseWithError<BuyerBusinessEntity>> {
    return this.client.patch<BuyerBusinessEntity, MsApiResponse<BuyerBusinessEntity>, BuyerBusinessUpdateDataPayload>(
      MsAdminApiEndPoints.buyers.update(id),
      payload,
      options
    );
  }

  /**
   * Update a buyer's details.
   */
  updateByBusinessId(
    id: string,
    payload: BuyerBusinessUpdateDataPayload,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponseWithError<BuyerBusinessEntity>> {
    return this.client.patch<BuyerBusinessEntity, MsApiResponse<BuyerBusinessEntity>, BuyerBusinessUpdateDataPayload>(
      MsAdminApiEndPoints.buyers.updateByBusinessId(id),
      payload,
      options
    );
  }

  /**
   * Delete a buyer.
   */
  delete(id: string, options?: AxiosRequestConfig): Promise<AxiosResponseWithError<boolean>> {
    return this.client.delete(`${MsAdminApiEndPoints.buyers}/${id}`, options);
  }

  /**
   * Update a buyer's details for loggedin user
   */
  updateByUser(
    userId: string,
    payload: BuyerBusinessUpdatePayload,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponseWithError<BuyerBusinessEntity>> {
    return this.client.patch<BuyerBusinessEntity, MsApiResponse<BuyerBusinessEntity>, BuyerBusinessUpdatePayload>(
      MsAdminApiEndPoints.buyers.update(userId),
      payload,
      options
    );
  }

  /**
   * Update a buyer's PGCToken
   */
  updatePGCToken(
    id: string,
    payload: BuyerBusinessUpdatePGCToken,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponseWithError<boolean>> {
    return this.client.patch<boolean, MsApiResponse<boolean>, BuyerBusinessUpdatePGCToken>(
      MsAdminApiEndPoints.buyers.update(id),
      payload,
      options
    );
  }

  /**
   * Create PGC User
   */
  createPGCUser(
    id: string,
    payload: BuyerBusinessCreatePGCUser,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponseWithError<boolean>> {
    return this.client.post<boolean, MsApiResponse<boolean>, BuyerBusinessCreatePGCUser>(
      MsAdminApiEndPoints.buyers.create(id),
      payload,
      options
    );
  }

  /**
   * Retrieves a buyer by business id.
   */
  getByBusinessId(
    businessId: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponseWithError<BuyerBusinessEntity>> {
    return this.client.get<BuyerBusinessEntity, MsApiResponse<BuyerBusinessEntity>>(
      MsAdminApiEndPoints.buyers.getByBusinessId(businessId),
      options
    );
  }

  /**
   * Retrieve all users for a buyer.
   */
  listUsers(
    buyerId: string,
    searchParams?: Partial<SearchParams>,
    options?: AxiosRequestConfig
  ): Promise<MsApiResponse<PagedDataSource<BuyerBusinessEntity>>> {
    return this.client.get<BuyerBusinessEntity[], MsApiResponse<PagedDataSource<BuyerBusinessEntity>>>(
      MsAdminApiEndPoints.buyers.listUsers(buyerId, searchParams),
      {
        params: searchParams,
        ...options,
      }
    );
  }

  /**
   * Link a user to a businees (creates a mapping)
   */
  linkUserToBusiness(
    payload: LinkUserToBusinessPayload,
    options?: AxiosRequestConfig
  ): Promise<MsApiResponse<unknown>> {
    return this.client.post<unknown[], MsApiResponse<unknown>>(
      MsAdminApiEndPoints.buyers.linkUserToBusiness(),
      payload,
      options
    );
  }

  /**
   * Sync Buyer at PGCorp
   */
  sync(buyerId: string, options?: AxiosRequestConfig): Promise<AxiosResponseWithError<BuyerBusinessEntity>> {
    return this.client.post<BuyerBusinessEntity, MsApiResponse<BuyerBusinessEntity>>(
      MsAdminApiEndPoints.buyers.sync(buyerId),
      undefined,
      options
    );
  }

  /**
   * Sync Buyer Webhooks at PGCorp
   */
  syncWebhooks(buyerId: string, options?: AxiosRequestConfig): Promise<AxiosResponseWithError<BuyerBusinessEntity>> {
    return this.client.post<BuyerBusinessEntity, MsApiResponse<BuyerBusinessEntity>>(
      MsAdminApiEndPoints.buyers.syncWebhooks(buyerId),
      undefined,
      options
    );
  }

  /**
   * Flush Buyer Cache
   */
  flushCache(buyerId: string, options?: AxiosRequestConfig): Promise<AxiosResponseWithError<unknown>> {
    return this.client.post<BuyerBusinessEntity, MsApiResponse<unknown>>(
      MsAdminApiEndPoints.buyers.flushCache(buyerId),
      undefined,
      options
    );
  }
}
