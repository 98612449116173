import type { PGCorpCreateOrderPayload } from '@wolfejs/perfectgift-api-js-mock/src/entities';
import type { AxiosRequestConfig } from 'axios';
import type { MsApiResponse } from 'ms-api-medusa';
import type {
  PGCandidateOrderEntity,
  PGCorpCandidateOrderPayload,
  PgOrderEntity,
  PgOrderItemEntity,
  PgOrderResponse,
} from '../types/orders';
import type { OrderSearchParamsQuery, PgPagedDataSource, PgSingleDataSource } from '../types/pgcorp';
import BaseResource from './base';
import { MsApiEndPoints } from './endPoints';

export class OrdersResource extends BaseResource {
  /**
   * List Orders
   *
   * @param searchParams
   * @param options
   */
  getOrders(searchParams?: OrderSearchParamsQuery, options?: AxiosRequestConfig) {
    return this.client.get<PgOrderEntity, MsApiResponse<PgPagedDataSource<PgOrderEntity>>>(
      MsApiEndPoints.pgcorp.getOrders(),
      {
        params: searchParams,
        ...options,
      }
    );
  }

  /**
   * Candidate order
   *
   * https://developers.perfectgift.com/#tag/pricing
   *
   * @param order order data
   * @param options Axios options
   */
  candidateOrder(data: PGCorpCandidateOrderPayload, options?: AxiosRequestConfig) {
    const retVal = this.client.post<PGCandidateOrderEntity, MsApiResponse<PGCandidateOrderEntity>>(
      MsApiEndPoints.pgcorp.createCandidateOrder(),
      data,
      options
    );
    return retVal;
  }

  /**
   * Get Candidate order
   *
   * https://developers.perfectgift.com/#tag/pricing/candidate/{uuid}
   *
   * @param order order data
   * @param options Axios options
   */
  getCandidateOrder(id: string, options?: AxiosRequestConfig) {
    return this.client.get<PGCandidateOrderEntity, MsApiResponse<PGCandidateOrderEntity>>(
      MsApiEndPoints.pgcorp.getCandidateOrder(id),
      options
    );
  }

  /**
   * Return order information, including status
   *
   * https://developers.perfectgift.com/#tag/Order/operation/Get%20Order%20Info
   *
   * @param id order uuid to fetch info from
   * @param options Axios options
   */
  orderInfo(id: string, options?: AxiosRequestConfig) {
    return this.client.get<PgOrderEntity, MsApiResponse<PgSingleDataSource<PgOrderEntity>>>(
      MsApiEndPoints.pgcorp.getOrderInfo(id),
      options
    );
  }

  /**
   * Place order
   *
   * https://developers.perfectgift.com/#tag/Order/operation/Place%20Order
   *
   * @param order order data
   * @param options Axios options
   */
  placeOrder(data: PGCorpCreateOrderPayload, options?: AxiosRequestConfig) {
    return this.client.post<PgOrderResponse[], MsApiResponse<PgSingleDataSource<PgOrderResponse>>>(
      MsApiEndPoints.pgcorp.placeOrder(),
      data,
      options
    );
  }

  /**
   * Return tracking numbers for an order
   *
   * https://developers.perfectgift.com/#tag/Order/operation/Get%20Order%20Tracking%20Info
   *
   * @param id order uuid to fetch info from
   * @param options Axios options
   */
  orderTrackingInfo(id: string, options?: AxiosRequestConfig) {
    return this.client.get<PgOrderEntity[], MsApiResponse<PgSingleDataSource<PgOrderEntity>>>(
      MsApiEndPoints.pgcorp.getOrderTrackingInfo(id),
      options
    );
  }

  /**
   * Cancel order (if possible)
   *
   * https://developers.perfectgift.com/#tag/Order/operation/Cancel%20Order
   *
   * @param id order uuid to fetch info from
   * @param options Axios options
   */
  cancelOrder(id: string, options?: AxiosRequestConfig) {
    return this.client.post<PgOrderEntity[], MsApiResponse<PgSingleDataSource<PgOrderEntity>>>(
      MsApiEndPoints.pgcorp.cancelOrder(id),
      options
    );
  }

  /**
   * Returns all items and their detailed information
   *
   * https://developers.perfectgift.com/#tag/Order/operation/Get%20Order%20Items
   *
   * @param id order uuid to fetch info from
   * @param searchParams order pagination params
   * @param options Axios options
   */
  async orderItems(id: string, searchParams?: OrderSearchParamsQuery, options?: AxiosRequestConfig) {
    return await this.client.get(MsApiEndPoints.pgcorp.getOrderItems(id), {
      params: searchParams,
      ...options,
    });
  }

  /**
   * Export all items
   *
   * @param id order id
   * @param options Axios options
   */
  async exportOrderItems(id: string, options?: AxiosRequestConfig) {
    return await this.client.get(MsApiEndPoints.pgcorp.exportOrderItems(id), {
      ...options,
    });
  }

  /**
   * Refund all order items
   *
   * https://developers.perfectgift.com/#tag/Order/operation/Refund%20all%20order%20items
   *
   * @param id order uuid to fetch info from
   * @param options Axios options
   */
  refundAllOrderItems(id: string, options?: AxiosRequestConfig) {
    return this.client.get<PgOrderEntity[], MsApiResponse<PgSingleDataSource<PgOrderEntity>>>(
      MsApiEndPoints.pgcorp.refundAllOrderItems(id),
      options
    );
  }

  /**
   * List Admin Orders
   *
   * @param searchParams
   * @param options
   */
  getAdminOrders(searchParams?: OrderSearchParamsQuery, options?: AxiosRequestConfig) {
    return this.client.get<PgOrderEntity, MsApiResponse<PgPagedDataSource<PgOrderEntity>>>(
      MsApiEndPoints.pgcorp.getAdminOrders(),
      {
        params: searchParams,
        ...options,
      }
    );
  }

  /**
   * Get Admin Order
   *
   * @param id order uuid to fetch info from
   * @param options Axios options
   */
  getAdminOrder(id: string, options?: AxiosRequestConfig) {
    return this.client.get<PgOrderEntity, MsApiResponse<PgSingleDataSource<PgOrderEntity>>>(
      MsApiEndPoints.pgcorp.getAdminOrder(id),
      options
    );
  }

  /**
   * List Admin Order Items
   *
   * @param id
   * @param searchParams
   * @param options
   */
  getAdminOrderItems(id: string, searchParams?: OrderSearchParamsQuery, options?: AxiosRequestConfig) {
    return this.client.get<PgOrderItemEntity, MsApiResponse<PgPagedDataSource<PgOrderItemEntity>>>(
      MsApiEndPoints.pgcorp.getAdminOrderItems(id),
      {
        params: searchParams,
        ...options,
      }
    );
  }
}
